import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
Copyrights &#169; 2024 Jaskaran Singh. all rights recieved.

    </footer>
  )
}

export default Footer;